<template>
  <div class="home-cities">
    <div class="home-cities__map">
      <shops-map
        v-if="shopsList"
        :is-show-shop-types="true"
        :shops-list="shopsList"
      />
      <div v-else class="loader-mini__container">
        <div class="loader-mini"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getShopsByFilters } from "@/api/shop";

export default {
  name: "HomeCities",
  components: { ShopsMap: () => import("@/components/maps/ShopsMap") },

  data() {
    return {
      shopsList: null,
    };
  },

  computed: {
    ...mapState("cityModule", ["selectedCity"]),
  },

  watch: {
    selectedCity: {
      handler() {
        getShopsByFilters(this.selectedCity.id).then((shops) => {
          this.shopsList = shops;
        });
      },
    },
  },

  created() {
    if (!this.shopsList && this.selectedCity?.id) {
      getShopsByFilters(this.selectedCity?.id).then((shops) => {
        this.shopsList = shops;
      });
    }
  },
};
</script>
